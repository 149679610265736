import React, { createContext, useReducer, useContext } from 'react';

type CustomStore<T> = [({ children }: { children: React.ReactNode }) => any, () => any, () => T];

export default function makeStore<T>(
  userReducer: any,
  initialState: any,
  localKey: string | null = null,
): CustomStore<T> {
  const storeContext = createContext(undefined);
  const dispatchContext = createContext(undefined);

  let reducer = userReducer;
  if (localKey) {
    try {
      initialState = JSON.parse(localStorage.getItem(localKey)!) || initialState;
    } catch {}
    reducer = (state: any, action: any) => {
      const newState = userReducer(state, action);
      localStorage.setItem(localKey, JSON.stringify(newState));
      return newState;
    };
  }

  const StoreProvider = ({ children }: { children: React.ReactNode }): any => {
    const [store, dispatch] = useReducer(reducer, initialState);

    return (
      <dispatchContext.Provider value={dispatch as any}>
        <storeContext.Provider value={store as any}>{children}</storeContext.Provider>
      </dispatchContext.Provider>
    );
  };

  function useStore(): any {
    return useContext(storeContext);
  }

  function useDispatch(): any {
    return useContext(dispatchContext);
  }

  return [StoreProvider, useDispatch, useStore];
}
