import { contentUrl } from '../api'

export const SAFETY_PROFILE = {
  name: 'SAFETY_PROFILE Screen',
  title: 'SAFETY_PROFILE',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'ADVERSE EVENTS FROM CLINICAL TRIALS',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/safety-profile/safety_profile_adverse_events@2x.png`, alt: "Safety Profile Adverse Events" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/safety-profile/mobile/safety_profile_adverse_events_mobile.jpg`, alt: "Safety Profile Adverse Events" }],
        },
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'LONG-TERM SAFETY PROFILE',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/safety-profile/safety_profile_longterm_safety@2x.png`, alt: "Long Term Safety Profile" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/safety-profile/mobile/safety_profile_longterm_safety_mobile.jpg`, alt: "Long Term Safety Profile" }],
        },
      }
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/safety-profile/safety_profile_references@2x.png`, alt: "Safety Profile References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/safety-profile/mobile/safety_profile_references_mobile.jpg`, alt: "Safety Profile References"  }],
        },
      }
    },
  ],
}
