import { contentUrl } from '../api'

export const PATIENT_IMPACT = {
  name: 'PATIENT_IMPACT Screen',
  title: 'PATIENT_IMPACT',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: "MULTILEVELIMAGEDROPDOWN",
      menuName: "VISIBLE RESULTS",
      subItems: [
        {
          menuTemplate: 'IMAGE',
          subMenuName: 'ADULTS',
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/adult-legs/AdultLegs.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/adult-legs/AdultLegsBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/adult-legs/AdultLegsAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 1 achieved a 4-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/adult-hand/AdultHand.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/adult-hand/AdultHandBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/adult-hand/AdultHandAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 2 achieved a 3-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/adult-torso/AdultTorso.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/adult-torso/AdultTorsoBefore.png`, title: "BASELINE:", text: "IGA 3 <br />(moderate)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/adult-torso/AdultTorsoAfter.png`, title: "WEEK 16:", text: "IGA 0 <br />(clear)" },
                title: "Adult patient 3 achieved a 3-point improvement in IGA",
                text: `<p>Actual patient treated with DUPIXENT. Not a clinical trial patient. Patient was on concomitant therapies, such as TCS, phototherapy, etc, at their prescribing physician’s discretion. Scoring was designated by the treating physician. Because this was a real-world patient, other factors may have influenced their treatment results. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ]
        },
        {
          menuTemplate: "IMAGE",
          subMenuName: "ADOLESCENTS",
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/adolescent/Adolescent.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/adolescent/AdolescentArmBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/adolescent/AdolescentArmAfter.png`, title: "WEEK 16:", text: "IGA 2 <br />(mild)" },
                title: "Adolescent patient achieved a 2-point improvement in IGA",
                text: `<p>Actual 12-year-old patient in the Phase 3 adolescent DUPIXENT trial. Patient had a baseline IGA of 4 and EASI of 31. Individual results may vary.</p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1 and at least a 2-point improvement from baseline.<sup>1</sup></p>
                <p>This adolescent patient would not have met the primary endpoint in the clinical trial based on their IGA score at Week 16</p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ],
        },
        {
          menuTemplate: "IMAGE",
          subMenuName: "CHILDREN",
          subItems: [
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/child-face/ChildFace.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/child-face/ChildFaceBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/child-face/ChildFaceAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 1 (6 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/child-wrist/ChildWrist.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/child-wrist/ChildWristBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/child-wrist/ChildWristAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 2 (10 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/child-foot/ChildFoot.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/child-foot/ChildFootBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/child-foot/ChildFootAfter.png`, title: "WEEK 16:", text: "IGA 1 <br />(almost clear)" },
                title: "Child patient 3 (9 years of age) achieved a 3-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1<sup>1</sup></p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
            {
              subMenuName: "",
              src: `${contentUrl}/patient-impact/visible-results/child-knees/ChildKnees.jpg`,
              contentTemplate: "MASKSLIDER",
              content: {
                before: { src: `${contentUrl}/patient-impact/visible-results/child-knees/ChildKneesBefore.png`, title: "BASELINE:", text: "IGA 4 <br />(severe)" },
                after: { src: `${contentUrl}/patient-impact/visible-results/child-knees/ChildKneesAfter.png`, title: "WEEK 16:", text: "IGA 3 <br />(moderate)" },
                title: "Child patient 4 (6 years of age) achieved a 1-point improvement in IGA",
                text: `<p>Actual patient in a Phase 3 pediatric DUPIXENT trial (LIBERTY AD PEDS). Patient was prescribed concomitant TCS based on the clinical trial program. Individual results may vary.<sup>2</sup></p>
                <p>A clinical responder was defined as a patient achieving IGA 0 or 1<sup>1</sup></p>
                <p class='small'>This patient would not have met the primary endpoint in the clinical trial based on their IGA score at Week 16</p>
                <p class='small'>IGA, Investigator’s Global Assessment; TCS, topical corticosteroids.</p>`,
              },
            },
          ],
        },
      ]
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'STUDY DESIGNS',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patient_impact_study_designs@2x.png`, alt: 'Study Designs'}],
          mobileImageList: [{srcMobile: `${contentUrl}/patient-impact/mobile/patient_impact_study_designs_mobile.jpg`, alt: 'Study Designs' }]
        }
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'REAL-WORLD DATA',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patient_impact_real_world_data@2x.png`, alt: 'Real world Data' }],
          mobileImageList: [{ srcMobile: `${contentUrl}/patient-impact/mobile/patient_impact_real_world_data_mobile.jpg`, alt: 'Real world Data' }]
        }
      }
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patient_impact_references@2x.png`, alt: "Safety Profile References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/patient-impact/mobile/patient_impact_references_mobile.jpg`, alt: "Safety Profile References" }],
        },
      }
    },
  ],
}
