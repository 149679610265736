import { contentUrl } from '../api'

export const PATIENT_SUPPORT_AND_DOSING = {
  name: 'DOSING Screen',
  title: 'DOSING',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'DOSING INFORMATION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dosing/dosing_dosing_information@2x.png`, alt: 'Dosing Information'}],
          mobileImageList: [{srcMobile: `${contentUrl}/dosing/mobile/dosing_dosing_information_mobile.jpg`, alt: 'Dosing Information'}]
        }
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'SIMPLE ADMINISTRATION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dosing/dosing_simple_administration@2x.png`, alt: 'Simple Administration'}],
          mobileImageList: [{srcMobile: `${contentUrl}/dosing/mobile/dosing_simple_administration_mobile.jpg`, alt: 'Simple Administration'}]
        }
      }
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dosing/dosing_references@2x.png`, alt: "Dosing References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/dosing/mobile/dosing_references_mobile.jpg`, alt: "Dosing References" }],
        },
      }
    },
  ],
}
