import { contentUrl } from '../api'

export const MOA = {
  name: 'DUPIXENT_IN_ACTION Action Screen',
  title: 'DUPIXENT_IN_ACTION',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'DUPIXENT IN ACTION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dupixent-in-action/dupixent_in_action@2x.png`, alt: 'Dupixent in Action'}],
          mobileImageList: [{srcMobile: `${contentUrl}/dupixent-in-action/mobile/dupixent_in_action_mobile.jpg`, alt: 'Dupixent in Action'}]
        }
      }
    },
    {
      menuTemplate: 'SINGLE',
      menuName: 'MODE OF ACTION',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dupixent-in-action/dupixent_in_action_mode_of_action@2x.png`, alt: 'Mode of Action'}],
          mobileImageList: [{srcMobile: `${contentUrl}/dupixent-in-action/mobile/dupixent_in_action_mode_of_action_mobile.jpg`, alt: 'Mode of Action'}]
        }
      }
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/dupixent-in-action/dupixent_in_action_references@2x.png`, alt: "Dupixent in Action References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/dupixent-in-action/mobile/dupixent_in_action_references_mobile.jpg`, alt: "Dupixent in Action References" }],
        },
      }
    },
  ],
}
