//@ts-nocheck
import React from 'react'
import { Header, Footer } from '@lxlabs/vc-components'
import { HEADER_CONTENT, FOOTER_CONTENT } from './config/app'
import VirtualBooth from './VirtualBooth'
import { useUiActions } from './reducers/ui'


export const App = () => {
  const uiSetters = useUiActions()
  return (
    <>
      <Header 
        content={HEADER_CONTENT} 
        events={{
          showForm: () => uiSetters.setShowForm(true)
          }
        }/>
          <VirtualBooth />
      <Footer content={FOOTER_CONTENT} />
    </>
  )
}
