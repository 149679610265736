import dupixentLogo from '../../assets/images/dupixent-logo.png';

export const HEADER_CONTENT = {
  menu: {
    logo: {
      src: `${dupixentLogo}`,
      alt: 'logo',
    },
    items: [
      {
        cta: 'FOR HEALTHCARE PROFESSIONALS ONLY',
        plain: true,
      },
      {
        cta: 'Summary of Product Characteristics',
        subNav: [
          {
            header: 'Summary of Product Characteristics',
          },
          {
            cta: 'Summary of Product Characteristics - English',
            link: 'https://www.ema.europa.eu/en/documents/product-information/dupixent-epar-product-information_en.pdf',
            disclaimer: 'false'
          },
        ],
      },
      {
        cta: 'Indication',
        subNav: [
          {
            cta:
              'DUPIXENT is indicated to treat adults and adolescents 12 years and older with moderate-to-severe atopic dermatitis, and children with sever atopic dermatitis aged 6-11 years old who are candidates for systemic therapy.',
          },
        ],
      },
      {
        cta: 'Keep in touch',
        event: 'showForm',
        hideOnMobile: true,
      },
      {
        cta: 'Medical Information',
        hideOnMobile: true,
        singleLink: 'https://www.regeneron.com/medical-inquiries',
      },
    ],
  },
}
