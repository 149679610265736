const menu = {
    loaded: true,
    menuItems: [
      {
        instance: 'default',
        title: '<b>Home</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
      },
      {
        instance: 'EFFICACY',
        title: '<b>Efficacy</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Explore Dupixent Data',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandData.mp4',
        loopTime: 5000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 7,
        selectable: true,
        content: {
          name: 'ClinicalData',
          text: 'Watch the journeys of Real Dupixent Patients',
          config: 'ClinicalProgramsConfig',
        },
        subHeading: [
          { name: 'clinical program', items: ['trial program', 'atopic dermatitis trials'] },
          { name: 'efficacy', items: ['itch reduction', 'skin clearance'] },
          { name: 'safety profile', items: ['adverse events & long-term safety profile', 'discontinuation rates'] },
          { name: 'other attributes', items: [] },
          {
            name: 'real-world data',
            items: ['real-world itch', ' real-world skin clearance', 'real-world persistence'],
          },
        ],
      },
      {
        instance: 'SAFETY_PROFILE',
        title: '<b>Safety Profile</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Uncover Visible Results',
        selectable: true,
        offset: 5,
        content: {
          name: 'VisibleResults',
          text: 'Uncover Real Visible Results',
          config: 'VisibleResultsConfig',
        },
        subHeading: [
          { name: 'adults', items: [] },
          { name: 'adolescents', items: [] },
          { name: 'children', items: [] },
          { name: 'study design' },
        ],
      },
      {
        instance: 'PATIENT_IMPACT',
        title: '<b>Patient Impact</b>',
        color: 'stripNavBgPrimary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'WATCH THE JOURNEYS OF REAL DUPIXENT PATIENTS',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandPatientStories.mp4',
        loopTime: 7000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 5,
        selectable: true,
        content: {
          name: 'PatientStories',
          text: 'Watch the journeys of Real Patient Stories',
          config: 'PatientSuccessStoriesConfig',
        },
        subHeading: [{ name: 'annie' }, { name: 'kristy' }, { name: 'rachel' }, { name: 'britton' }],
      }, 
      {
        instance: 'MOA',
        title: '<b>DUPIXENT in Action</b>',
        color: 'stripNavBgSecondary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'Study Designs',
        selectable: true,
        content: {
          name: 'StudyDesigns',
          text: 'Study Designs',
          config: 'StudyDesignConfig',
        },
      },     
      {
        instance: 'PATIENT_SUPPORT_AND_DOSING',
        title: '<b>Dosing</b>',
        color: 'stripNavBgSecondary',
        mobileColor: 'stripNavMobileBgPrimary',
        hotspot: 'WATCH THE JOURNEYS OF REAL DUPIXENT PATIENTS',
        text: '',
        video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandPatientStories.mp4',
        loopTime: 7000,
        autoPlay: true,
        playVideoInSitu: false,
        offset: 5,
        selectable: true,
        content: {
          name: 'PatientStories',
          text: 'Watch the journeys of Real Patient Stories',
          config: 'PatientSuccessStoriesConfig',
        },
        subHeading: [{ name: 'annie' }, { name: 'kristy' }, { name: 'rachel' }, { name: 'britton' }],
      },
      {
        instance: 'KEEP_IN_TOUCH',
        contactUsTitle: 'Keep in touch',
        linkTitle: 'Keep in touch',
        color: 'stripNavBgSecondary',
        mobileColor: 'stripNavMobileBgPrimary',
        openForm: true,
        showInDropdown: false,
        mobileOnly: true,
      },
      {
        instance: 'default',
        contactUsTitle: 'Medical Information',
        linkTitle: 'Medical Information',
        linkTitleUrl: 'https://www.regeneron.com/medical-inquiries',
        color: 'stripNavBgSecondary',
        mobileColor: 'stripNavMobileBgPrimary',
        mobileOnly: true,
      },
      // {
      //   contactUsTitle: 'Medical Information',
      //   linkTitle: 'Medical Information',
      //   linkTitleUrl: 'https://www.sanofimedicalinformation.com/s/?language=en_US&CN=US',
      //   color: 'stripNavBgSecondary',
      //   mobileColor: 'stripNavMobileBgLinks',
      //   openForm: false,
      //   showInDropdown: false,
      // },
      // {
      //   instance: 'REP',
      //   title: 'Request a Rep',
      //   color: 'stripNavBgSecondary',
      //   hotspot: 'Request a Rep',
      //   text: '',
      //   video: 'https://uploads.dupixentvirtualbooth.com/assets/video/StandRep.mp4',
      //   loopTime: 5000,
      //   autoPlay: true,
      //   playVideoInSitu: false,
      //   offset: 5,
      //   selectable: true,
      //   important: true,
      //   hasIcon: true,
      //   content: {
      //     name: 'RequestRep',
      //     text: 'Request a Rep',
      //     config: 'RequestRepConfig',
      //   },
      // },
    ],
  };

export default menu;