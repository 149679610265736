import { contentUrl } from '../api'

export const EFFICACY = {
  name: 'EFFICACY Screen',
  title: 'EFFICACY',
  mobileType: "mobile",
  content: [
    {
      menuTemplate: 'SINGLE',
      menuName: 'OVERVIEW',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/efficacy/efficacy_overview@2x.png`, alt: 'Efficacy Overview' }],
          mobileImageList: [{srcMobile: `${contentUrl}/efficacy/mobile/efficacy_overview_mobile.png`, alt: 'Efficacy Overview' }]
        }
      }
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'LESION EXTENT & SEVERITY',
      subItems: [
        {
          subMenuName: 'ADULTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_lesion_adults@2x.png`, alt: "Lesion Adults" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_lesion_adults_mobile.jpg`, alt: "Lesion Adults"  }],
          }
        },
        {
          subMenuName: 'ADOLESCENTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_lesion_adolescents@2x.png`, alt: "Lesion Adolescents" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_lesion_adolescents_mobile.jpg`, alt: "Lesion Adolescents" }],
          }
        },
        {
          subMenuName: 'CHILDREN',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_lesion_children@2x.png`, alt: "Lesion Children" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_lesion_children_mobile.jpg`, alt: "Lesion Children" }],
          }
        }
      ]
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'PRURITUS INTENSITY',
      subItems: [
        {
          subMenuName: 'ADULTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_pruritus_adults@2x.png`, alt: "Pruritus Adults" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_pruritus_adults_mobile.jpg`, alt: "Pruritus Adults" }],
          }
        },
        {
          subMenuName: 'ADOLESCENTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_pruritus_adolescents@2x.png`, alt: "Pruritus Adolescents" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_pruritus_adolescents_mobile.jpg`, alt: "Pruritus Adolescents" }],
          }
        },
        {
          subMenuName: 'CHILDREN',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_pruritus_children@2x.png`, alt: "Pruritus Children" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_pruritus_children_mobile.jpg`, alt: "Pruritus Children" }],
          }
        },
      ]
    },
    {
      menuTemplate: 'DROPDOWN',
      menuName: 'QUALITY OF LIFE',
      subItems: [
        {
          subMenuName: 'ADULTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_qol_adults@2x.png`, alt: "QoL Adults" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_qol_adults_mobile.jpg`, alt: "QoL Adults" }],
          }
        },
        {
          subMenuName: 'ADOLESCENTS',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_qol_adolescents@2x.png`, alt: "QoL Adolescents" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_qol_adolescents_mobile.jpg`, alt: "QoL Adolescents" }],
          }
        },
        {
          subMenuName: 'CHILDREN',
          contentTemplate: 'IMAGE',
          content: {
            imageList: [{ src: `${contentUrl}/efficacy/efficacy_qol_children@2x.png`, alt: "QoL Children" }],
            mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_qol_children_mobile.jpg`, alt: "QoL Children" }],
          }
        },
      ]
    },
    {
      menuTemplate: 'TEXT',
      menuName: 'References',
      content: {
        contentTemplate: 'IMAGE',
        content: {
          imageList: [{ src: `${contentUrl}/efficacy/efficacy_references@2x.png`, alt: "Efficacy References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/efficacy/mobile/efficacy_references_mobile.jpg`, alt: "Efficacy References" }],
        },
      }
    },
  ],
}
