//@ts-nocheck
import React, { useState, useRef, Suspense, lazy, useEffect } from 'react'
import {
  Loading,
  ContentSidebar,
  MobileLandscapeOverlay,
  isMobile,
  StripNavigation,
  Alert,
  gaInitalise,
  gaLogEvent,
  Form,
  EventReducer,
  EventName,
  LeavingDisclaimer,
  styled,
  VideoFlyThrough
} from '@lxlabs/vc-components/dist/index'
import { useUiActions, useUiState } from './reducers/ui'

import { MOBILELANDSCAPEOVERLAY_CONTENT, CONTACTFORM_CONTENT, INTRO_CONTENT, LEAVING_DISCLAIMER } from './config/app'
import * as CONTENT from './config/content'
import { contentUrl, uploadsUrl } from './config/api'
import { ContentTypes } from './config/content'
import menu from './config/menu'
import showWarning from './utils/showWarning'
import { hasSeenFlyThrough, setHasSeenFlyThrough } from './utils/localStorage'

import BlackFade from './components/BlackFade'

import menuBg from './assets/images/menu-bg.png'
import logoSymbol from './assets/images/logo-symbol-white-small.png'
import menuBtnGreen from './assets/images/menu-burger.png'
import dupixentLogo from './assets/images/dupixent-logo.png'
// import { NONAME } from 'dns'

const AppScene = lazy(() => import('./AppScene'))
const { useEventActions, useEventState } = EventReducer

process.env.REACT_APP_GTM_ID && gaInitalise({ gtmId: process.env.REACT_APP_GTM_ID })

function App() {
  const [content, setContent] = useState<ContentTypes | ''>('')
  const [showLoader, setShowLoader] = useState(true)
  const [blackFadeClass, setBlackFadeClass] = useState('hidden')
  const [leavingDisclaimer, setLeavingDisclaimer] = useState(null)
  const [showFlyThroughVideo, setShowFlyThroughVideo] = useState(!hasSeenFlyThrough());
  const [showSkip, setShowSkip] = useState(false);

  const [activeTab, setActiveTab] = useState<string>('')
  const uiState = useUiState()
  const uiSetters = useUiActions()
  const eventState = useEventState()
  const eventSetters = useEventActions()

  const jumpTimer = useRef(null)
  const fadeTimer = useRef(null)

  useEffect(() => {
    const scene = document.querySelector("[id^='aad-scene']")
    if (scene) {
      if (uiState.showPopup && isMobile) {
        scene.style.visibility = "hidden"
      } else {
        scene.style.visibility = "visible"
      }
    }

  }, [uiState.showPopup])

  useEffect(() => {
    document.addEventListener('click', (ev) => {
      if (ev.target.tagName === 'A') {
        if (ev.target.dataset.disclaimer !== "false") {
          ev.preventDefault();
          setLeavingDisclaimer({
            href: ev.target.href,
            text: ev.target.innerHTML
          })
        }
        
      }
    })
  }, [])

  useEffect(() => {
    if (!showFlyThroughVideo) {
        setHasSeenFlyThrough()
    } else {
      const timer = setTimeout(() => {
        setShowSkip(true);
      }, 10000)
      return () => clearTimeout(timer);
    }
    console.log('USE EFFECT show fly through video')
  }, [showFlyThroughVideo])


  const onClose = () => {
    uiSetters.setShowPopup(false)
    fadeInOut(1200)
    clearTimeout(jumpTimer.current)
    jumpTimer.current = setTimeout(() => {
      eventSetters.setEvent(EventName.JUMP_TO_START)
    }, 600)
  }

  const showFade = () => {
    setBlackFadeClass('visible')
  }

  const hideFade = () => {
    setBlackFadeClass('click-through')
  }

  const fadeInOut = (time: number) => {
    showFade()
    clearInterval(fadeTimer.current)
    fadeTimer.current = setTimeout(() => {
      hideFade()
    }, time)
  }

  const onNavigationSelect = (instance: string) => {
    uiSetters.setShowForm(false)
    if (instance === 'default') {
      onClose()
    } else {
      uiSetters.setShowPopup(false)
      uiSetters.setAction('')
      fadeInOut(1200)

      clearTimeout(jumpTimer.current)
      jumpTimer.current = setTimeout(() => {
        eventSetters.setEvent(EventName.JUMP_TO_OBJECT, instance)
      }, 600)
    }
  }

  return (
    <>
      <Alert
          message="For an optimal experience, please switch your browser to Google Chrome."
          closeText="OK"
          show={showWarning()}
          menuBg={menuBg}
          logoSymbol={logoSymbol}
        />
      {showFlyThroughVideo ?
        <Container style={{ background: 'black' }}>
          <SkipFlyThrough showSkip={showSkip} onClick={() => skipButton(setShowFlyThroughVideo)}>SKIP</SkipFlyThrough>
          <VideoFlyThrough
            style={{ margin: '0 auto' }}
            id='video-flytrough'
            src={`${uploadsUrl}/assets/videos/FlyThrough.mp4`}
            onEnd={() => {
            setShowFlyThroughVideo(false);
            }}
          />
        </Container> : 
        
        <Container>
        {isMobile && (
          <MobileLandscapeOverlay
            content={MOBILELANDSCAPEOVERLAY_CONTENT.overlayMessage}
            mobileLandscapeImage={`${contentUrl}/images/mobile-landscape-overlay.svg`}
          />
        )}
        <StripNavigation
          skewDegrees={'45deg'}
          headerHeight={90}
          headerOffset={50}
          filter={false}
          navItems={menu.menuItems}
          onSelect={onNavigationSelect}
          logo={dupixentLogo}
          logoWidth={'160px'}
          menuBtn={menuBtnGreen}
          multipleLines={false}
          active={activeTab ? activeTab : 'default'}
          showForm={uiState.showForm}
          setShowForm={(show) => {
            if (uiSetters.showForm !== show) {
              fadeInOut(600)
              if (show) {
                setActiveTab("KEEP_IN_TOUCH")
              }
              setTimeout(() => {
                uiSetters.setShowForm(show)
              }, 600)
            }
          }}
        />
        {uiState.showPopup && (
          <>
            <ContentSidebar contents={CONTENT[content as ContentTypes]} onClose={onClose} />
          </>
        )}

        {showLoader && <Loading isReady={uiState.isReady} content={INTRO_CONTENT} setShowLoader={setShowLoader} />}

        <BlackFade className={`${blackFadeClass}`} />
        {uiState.showForm && <Form
          url={process.env.REACT_APP_CONTACT_US_ENDPOINT}
          content={CONTACTFORM_CONTENT}
          showForm={uiState.showForm}
          offset={isMobile ? 90 : 0}
          setShowForm={() => {
            eventSetters.setEvent(EventName.JUMP_TO_START)
            setTimeout(() => {
              uiSetters.setShowForm(false)
            }, 200)
        
          }}
        />}

        <Suspense fallback={<></>}>
          <AppScene
            eventState={eventState}
            eventSetters={eventSetters}
            setActiveTab={setActiveTab}
            setContent={setContent}
            uiState={uiState}
            uiSetters={uiSetters}
          />
        </Suspense>
        {leavingDisclaimer && 
          <LeavingDisclaimer 
            setShowModal={setLeavingDisclaimer} 
            disclaimer={leavingDisclaimer} 
            disclaimerCopy={LEAVING_DISCLAIMER} 
          />
        }
      </Container>
      }
    </>
  )
}

function skipButton(setShowFlyThroughVideo){
  setShowFlyThroughVideo(false)
  gaLogEvent({
    event: "action",
    DLV_content_action_type: "flyThroughSkipped",
  })
}

const SkipFlyThrough = styled.p<{showSkip: boolean}>`
  opacity: ${(props) => props.showSkip ? '1' : '0'};
  bottom: 5%;
  color: white;
  font-size: 1.25rem;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity 1s ease-in-out 0.75s;
  text-shadow: 0 0 4px black;
  z-index: 100;
  :hover {
    color: #f9f9f9;
    cursor: pointer;
    font-size: 1.3rem;
  }
`

const Container = styled.div`
  height: calc(100% - 32px - 130px - 100px);
  position: relative;
  @media screen and  (max-width: 768px) {
    margin-top: 50px;
  }
`

export default App
