import { contentUrl } from '../api'

export const SCREEN_OVERLAY = {
  name: '',
  title: '',
  mobileType: "mobile",
  content: [
    {
      content: {
        contentTemplate: "IMAGE",
        content: {
          imageList: [{ src: `${contentUrl}/patient-impact/patientimpact_studydesigns@2x.png`, alt: "Patient Impact References" }],
          mobileImageList: [{ srcMobile: `${contentUrl}/patient-impact/patientimpact_studydesigns@2x.png`, alt: "Patient Impact References" }],
        },
      },
    },
  ],
}
